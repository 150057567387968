<template>
  <nav class="bfla-nav">
    <div class="bfla-nav__container">
      <a
        v-for="(item, index) in menu"
        :key="index"
        class="bfla-nav__link"
        :class="{'active': item.active}"
        :href="item.href"
      >
        {{ item.text }}
      </a>
      <a v-if="isLive" :href="themeUrls.Live" class="bfla-nav__live">
        <img src="./live-logo.svg" alt="Live">
      </a>
    </div>
  </nav>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'BFLANav',
  props: {
    menu: Array,
    isLive: Boolean,
  },
  computed: {
    ...mapGetters('settings', ['themeUrls']),
  },
};
</script>

<style lang="scss" scoped>
  @import "./Nav";
</style>
