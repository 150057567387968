<template>
  <div>
    <header class="bfla-header">
      <div class="bfla-header__container">
        <div class="bfla-header__item">
          <Button
            variant="clear"
            size="lg"
            shape="square"
            icon-left="menu"
            class="bfla-header__toggle"
            @click="openMenu"
          >
          </Button>
          <div class="bfla-header__social">
            <a
              v-for="(social, index) in socialList"
              :key="index"
              class="bfla-header__social-item"
              :href="social.href"
              :target="social.target ? social.target : ''"
            >
              <Icon :name="social.icon"></Icon>
            </a>
          </div>
        </div>
        <a :href="themeUrls.Main" class="bfla-header__logo">
          <span class="bfla-header__logo-text bfla-header__logo-text_left">RunIn</span>
          <img :src="themeImages.logo" alt="Runin" class="bfla-header__logo-img">
          <span class="bfla-header__logo-text bfla-header__logo-text_right">ЗаБегай</span>
        </a>
        <div class="bfla-header__item bfla-header__item-right">
          <div class="bfla-header__lang">
            <LangSwitch
              class="rr-header__col-item"
              :locales="themeSettings.languages"
              :locale="$lang"
              :dropdownOptions="{ triggerId: 'headerLangSwitch', placement: 'bottom-end' }"
              @change="changeLang"
            >
              <template v-slot:default>
                <Button
                  id="headerLangSwitch"
                  variant="clear"
                  size="lg"
                  shape="square"
                  icon-left="global"
                  class="bfla-header__lang-button"
                >
                </Button>
              </template>
            </LangSwitch>
          </div>
          <Button
            v-if="!isAuthorized"
            variant="clear"
            shape="circle"
            :href="`${themeUrls.Main}/login`"
          >
            <Avatar
              bordered
            ></Avatar>
          </Button>
          <template v-else>
            <Button
              variant="clear"
              shape="circle"
              ref="avatar"
            >
              <Avatar
                :username="user && user.fullName"
                :img="user && user.avatarSmallUrl"
                bordered
              />
            </Button>
            <Dropdown
              trigger="avatar"
              listeners="hover click"
              placement="bottom-end"
              strategy="fixed"
              class="bfla-header__dropdown"
            >
              <template slot="items">
                <div
                  class="base-dropdown__item"
                  v-for="(profileItem, i) in profileNav"
                  v-show="!profileItem.hidden"
                  :key="i"
                >
                  <div class="divider" v-if="profileItem.isDivider"></div>
                  <a class="base-dropdown__link" :href="profileItem.link" v-else>
                    <Icon class="base-dropdown__icon" :name='profileItem.icon'></Icon>
                    <span class="base-dropdown__text">{{ profileItem.name }}</span>
                  </a>
                </div>
                <div class="divider"></div>
                <div class="base-dropdown__item">
                  <a :href="`${themeUrls.Main}/Auth/LogOff`" class="base-dropdown__link">
                    <Icon class="base-dropdown__icon" name="logout"></Icon>
                    <span class="base-dropdown__text">Выйти</span>
                  </a>
                </div>
              </template>
            </Dropdown>
          </template>
        </div>
      </div>
    </header>
    <Nav :menu="menu" :isLive="isLive" />
    <HeaderMobileNav
      v-model="mobileNavOpened"
      :nav="menu"
      :locales="themeSettings.languages"
      :locale="$lang"
      @changeLang="changeLang"
    >
      <template v-slot:logo>
        <a :href="themeUrls.Main" class="rr-mobile-nav__logo">
          <img :src="themeImages.logo" alt="runin" width="40">
        </a>
        <a v-if="isLive" :href="themeUrls.Live">
          <img src="./live-logo.svg" alt="Live">
        </a>
      </template>
    </HeaderMobileNav>
  </div>
</template>

<script>
import getTodayEventsAmount from '@/assets/js/getTodayEventsAmount';
import { mapGetters } from 'vuex';
import { setCulture } from '@/utils';
import Avatar from '@/components/Avatar/Avatar.vue';
import HeaderMobileNav from '@/components/HeaderMobileNav/HeaderMobileNav.vue';
import Dropdown from '@/components/Dropdown/Dropdown.vue';
import LangSwitch from '@/components/LangSwitch/LangSwitch.vue';
import Button from '@rr-component-library/button/src/main';
import eventsApi from '@/services/events-api';
import Nav from './Nav.vue';
import ru from '../locales/ru.json';
import en from '../locales/en.json';

let themeUrls = null;

export default {
  name: 'BFLAHeader',
  i18n: {
    messages: {
      ru,
      en,
    },
  },
  components: {
    Avatar,
    HeaderMobileNav,
    Dropdown,
    LangSwitch,
    Button,
    Nav,
  },
  data() {
    return {
      mobileNavOpened: false,
      socialList: [
        {
          href: 'https://www.instagram.com/runin.by/',
          icon: 'instagram',
          target: '_blank',
        },
        {
          href: 'https://t.me/RunInBy',
          icon: 'telegram',
          target: '_blank',
        },
        {
          href: 'mailto:runin@bfla.eu',
          icon: 'mail',
        },
      ],
      menu: [
        {
          text: this.$t('header.events'),
          href: themeUrls.Events,
          icon: 'calendar',
          items: [
            {
              text: this.$t('header.upcoming'),
              link: `${themeUrls.Events}/events`,
            },
            {
              text: this.$t('header.justNow'),
              link: `${themeUrls.Events}/events?e=1`,
            },
            {
              text: this.$t('header.past'),
              link: themeUrls.Results,
            },
          ],
          open: false,
        },
        {
          text: this.$t('header.results'),
          href: themeUrls.Results,
          icon: '1st-place-medal',
          active: true,
        },
        {
          text: this.$t('header.contacts'),
          href: `${themeUrls.Events}/Contacts`,
          icon: 'phone',
        },
      ],
      profileNav: [
        {
          name: this.$t('header.profile'),
          icon: 'user',
          link: `${themeUrls.Main}/Account`,
        },
        {
          name: this.$t('header.registrations'),
          icon: 'badge',
          link: `${themeUrls.Main}/Account/RegistrationList`,
        },
        {
          name: this.$t('header.orders'),
          icon: 'shopping-bag',
          link: `${themeUrls.Main}/Account/OrderList`,
        },
      ],
      isLive: false,
    };
  },
  computed: {
    ...mapGetters('settings', ['theme', 'themeImages', 'themeUrls', 'themeSettings']),
    ...mapGetters('user', ['user', 'isAuthorized']),
  },
  methods: {
    openMenu() {
      this.mobileNavOpened = true;
    },
    changeLang(lang) {
      setCulture(lang, this.themeUrls.Main);
    },
    async getLiveCount() {
      const result = await eventsApi.getLiveCount();
      if (result.data > 0) {
        this.isLive = true;
      }
    },
  },
  beforeCreate() {
    themeUrls = this.$store.getters['settings/themeUrls'];
  },
  mounted() {
    if (!this.themeSettings.noLive) {
      this.getLiveCount();
    }

    this.getLiveCount();

    const todayEventsItem = this.menu[0].items[1];

    if (!todayEventsItem) return;

    getTodayEventsAmount().then((eventsAmount) => {
      todayEventsItem.hidden = eventsAmount === 0;
    });
  },
};
</script>

<style lang="scss" scoped>
@import "./Header";
</style>
